<template>
  <Page
    has-actions
    :subtitle="`${total} ${$tc('result', total <= 1 ? 1 : 2)}`"
    :loading="vouchers_loading"
  >
    <div v-if="$can('read', 'orders.vouchers')" slot="actions">
      <div class="field has-addons">
        <div class="is-flex mr-2">
          <b-button
            v-if="$device.mobile"
            size="is-small"
            rounded
            @click="showMobile()"
          >
            <b-icon icon="magnify" size="is-small" />
          </b-button>
          <b-input
            ref="search"
            v-model="headers[4].input"
            :placeholder="placeholders.voucher.code"
            icon="magnify"
            rounded
            :size="$device.mobile ? 'is-small' : ''"
            :class="{ expand: searchFocused, 'hide-search': $device.mobile }"
            @input="getVouchers()"
            @blur="hideMobile()"
          />
        </div>
        <b-dropdown
          ref="dropdown"
          aria-role="list"
          position="is-bottom-left"
          :close-on-click="false"
          scrollable
          max-height="400px"
          append-to-body
        >
          <div slot="trigger">
            <b-button
              icon-left="dots-vertical"
              type="is-default"
              :size="$device.mobile ? 'is-small' : ''"
              rounded
            />
          </div>

          <b-dropdown-item
            v-if="$can('create', 'orders.vouchers')"
            aria-role="listitem"
            @click="openForm(-1, {})"
          >
            <b-icon icon="plus" size="is-small" />
            {{ $t("create_voucher") }}
          </b-dropdown-item>

          <b-dropdown-item separator />

          <b-dropdown-item
            aria-role="listitem"
            class="pr-3"
            @click="showColumnsTogle = !showColumnsTogle"
          >
            <b-icon icon="view-column" size="is-small" />
            {{ $tc("column", 2) }}
            <b-icon
              class="is-pulled-right"
              :icon="!showColumnsTogle ? 'chevron-down' : 'chevron-up'"
              size="is-small"
            />
          </b-dropdown-item>
          <template v-if="showColumnsTogle">
            <template v-for="item in headers">
              <b-dropdown-item
                :key="item.key"
                aria-role="listitem"
                :focusable="false"
                custom
              >
                <b-switch v-model="item.display" type="is-success">
                  {{ item.label }}
                </b-switch>
              </b-dropdown-item>
            </template>
          </template>
        </b-dropdown>
      </div>
    </div>

    <Card v-if="$can('read', 'orders.vouchers')" content-class="c-table">
      <b-table
        :data="items"
        :loading="vouchers_loading"
        hoverable
        mobile-cards
        striped
        backend-sorting
        sort-multiple
        :sort-multiple-data="sortingPriority"
        sort-multiple-key="shiftKey"
        :total="total"
        :row-class="
          (row, index) => (row.id == updated_voucher ? 'bg-updated' : '')
        "
        @sort="sortPressed"
        @sorting-priority-removed="sortingPriorityRemoved"
      >
        <template v-for="head in headers">
          <b-table-column
            :key="head.value + '_' + head.display"
            :visible="head.display"
            :field="head.value"
            :label="head.label"
            :width="head.width"
            :centered="head.centered"
            :sortable="head.sortable"
            :searchable="head.searchable"
            v-bind="head"
            header-class="sticky-header"
          >
            <template v-if="head.searchable" slot="searchable">
              <template v-if="head.filter_type == 'date'">
                <b-datepicker
                  v-model="head.input"
                  rounded
                  :placeholder="placeholders.date.day"
                  size="is-small"
                  editable
                  :min-date="new Date('01/01/2013')"
                  @input="getVouchers()"
                />
                <span class="custom-date-filter" @click="showDrop(head.value)">
                  <b-icon icon="filter" size="is-small" />
                </span>
                <b-dropdown
                  :ref="`filter_drop_${head.value}`"
                  aria-role="list"
                  class="is-flex"
                >
                  <b-dropdown-item
                    aria-role="listitem"
                    @click="changeFilter(head.value, 'is')"
                  >
                    {{ $t("is") }}
                  </b-dropdown-item>

                  <b-dropdown-item
                    aria-role="listitem"
                    @click="changeFilter(head.value, 'greater')"
                  >
                    {{ $t("is") }} &gt;
                  </b-dropdown-item>

                  <b-dropdown-item
                    aria-role="listitem"
                    @click="changeFilter(head.value, 'less')"
                  >
                    {{ $t("is") }} &lt;
                  </b-dropdown-item>
                </b-dropdown>
              </template>
              <template v-else-if="head.filter_type == 'select'">
                <b-select-validation
                  v-model="head.input"
                  rounded
                  size="is-small"
                  :expanded="true"
                  @change="getVouchers()"
                >
                  <template>
                    <option
                      v-for="opt in head.filter_values"
                      :key="opt[head.filter_id]"
                      :value="opt[head.filter_id]"
                    >
                      {{ opt[head.filter_text] }}
                    </option>
                  </template>
                </b-select-validation>
              </template>
              <template v-else-if="head.filter_type == 'boolean'">
                <b-select-validation
                  v-model="head.input"
                  rounded
                  expanded
                  size="is-small"
                  @change="getVouchers()"
                >
                  <template>
                    <option :value="true">
                      {{ $t("yes") }}
                    </option>
                    <option :value="false">
                      {{ $t("no") }}
                    </option>
                  </template>
                </b-select-validation>
              </template>
              <template v-else>
                <b-input
                  v-model="head.input"
                  :placeholder="$root.getFilterPlaceholder(head)"
                  rounded
                  size="is-small"
                  icon-right="filter"
                  icon-right-clickable
                  @icon-right-click="showDrop(head.value)"
                  @input="getVouchers"
                />
                <b-dropdown
                  :ref="`filter_drop_${head.value}`"
                  aria-role="list"
                  class="is-flex"
                >
                  <template v-if="head.filter_type == 'numeric'">
                    <b-dropdown-item
                      v-for="filter in filtersData.numeric"
                      :key="filter.value"
                      aria-role="listitem"
                      @click="changeFilter(head.value, filter.value)"
                    >
                      {{ filter.label }}
                    </b-dropdown-item>
                  </template>

                  <template v-else>
                    <b-dropdown-item
                      v-for="filter in filtersData.char"
                      :key="filter.value"
                      aria-role="listitem"
                      @click="changeFilter(head.value, filter.value)"
                    >
                      {{ filter.label }}
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
              </template>
            </template>

            <!-- site -->
            <template v-if="head.value == 'site_id'" v-slot="props">
              <img
                height="20"
                width="20"
                :src="'/img/sites/' + props.row.site_id + '.svg'"
              />
            </template>

            <template v-else-if="head.value == 'id'" v-slot="props">
              <a
                v-if="
                  $can('read', 'orders.vouchers') ||
                  $can('update', 'orders.vouchers')
                "
                href="javascript:;"
                @click="openForm(props.index, props.row)"
                >{{ props.row.id }}</a
              >
              <span v-else>{{ props.row.id }}</span>
            </template>

            <!-- User -->
            <template v-else-if="head.value === 'user'" v-slot="props">
              <router-link
                v-if="props.row.user"
                :to="`/users/${props.row.user.id}`"
              >
                {{ props.row.user && props.row.user.name }}
              </router-link>
              <template v-else> - </template>
            </template>

            <!-- amount -->
            <template v-else-if="head.value === 'amount'" v-slot="props">
              {{ props.row.amount && props.row.amount.formatted }}
            </template>

            <!-- min_amount -->
            <template v-else-if="head.value === 'min_amount'" v-slot="props">
              {{
                (props.row.min_amount && props.row.min_amount.formatted) || "-"
              }}
            </template>

            <!-- created_at -->
            <template v-else-if="head.value === 'created_at'" v-slot="props">
              <span>
                {{
                  (props.row.created_at && props.row.created_at.formatted) ||
                  "-"
                }}
                <span class="has-text-grey-light">{{
                  props.row.created_at && props.row.created_at.time
                }}</span>
              </span>
            </template>

            <!-- starts_at -->
            <template v-else-if="head.value === 'starts_at'" v-slot="props">
              <span>
                {{
                  (props.row.starts_at && props.row.starts_at.formatted) || "-"
                }}
                <span class="has-text-grey-light">{{
                  props.row.starts_at && props.row.starts_at.time
                }}</span>
              </span>
            </template>

            <!-- ends_at -->
            <template v-else-if="head.value === 'ends_at'" v-slot="props">
              <span>
                {{ (props.row.ends_at && props.row.ends_at.formatted) || "-" }}
                <span class="has-text-grey-light">{{
                  props.row.ends_at && props.row.ends_at.time
                }}</span>
              </span>
            </template>

            <!-- voucher -->
            <template v-else-if="head.value == 'voucher'" v-slot="props">
              <span>
                <b-icon
                  v-if="head.value == 'voucher' && props.row.used"
                  icon="check"
                  type="is-success"
                  size="is-small"
                />
                {{ props.row[head.value] || "-" }}
              </span>
            </template>

            <!-- brand -->
            <template v-else-if="head.value == 'brand_id'" v-slot="props">
              {{ props.row.brand.label || "-" }}
            </template>

            <!-- category -->
            <template v-else-if="head.value == 'category_id'" v-slot="props">
              {{ props.row.category.label || "-" }}
            </template>

            <template v-else-if="head.value == 'actions'" v-slot="props">
              <b-dropdown
                :key="props.row.id"
                aria-role="list"
                position="is-bottom-left"
                append-to-body
              >
                <div slot="trigger">
                  <b-icon
                    icon="dots-vertical"
                    size="is-small"
                    class="is-clickable"
                  />
                </div>

                <b-dropdown-item
                  v-if="
                    $can('read', 'orders.vouchers') ||
                    $can('update', 'orders.vouchers')
                  "
                  aria-role="listitem"
                  @click="openForm(props.index, props.row)"
                >
                  <b-icon icon="eye" size="is-small" />
                  {{ $t("view") }}
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="$can('delete', 'orders.vouchers') && props.row.active"
                  aria-role="listitem"
                  @click="confirmDeleting(props.index, props.row.id)"
                >
                  <b-icon icon="delete" type="is-danger" size="is-small" />
                  {{ $t("delete") }}
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="$can('delete', 'orders.vouchers') && !props.row.active"
                  aria-role="listitem"
                  @click="restoreVoucher(props.index, props.row.id)"
                >
                  <b-icon icon="restore" type="is-success" size="is-small" />
                  {{ $t("restore") }}
                </b-dropdown-item>
              </b-dropdown>
            </template>

            <template v-else v-slot="props">
              <b-icon
                v-if="typeof props.row[head.value] === 'boolean'"
                :icon="props.row[head.value] ? 'check' : 'close'"
                :type="props.row[head.value] ? 'is-success' : 'is-danger'"
                custom-size="mdi-18px"
              />
              <template v-else>
                {{ props.row[head.value] || "-" }}
              </template>
            </template>
          </b-table-column>
        </template>
        <EmptyBlock slot="empty" icon="ticket-percent" />
      </b-table>
      <Pagination
        :per-page="perPage"
        :per-pages="perPages"
        :total="total"
        :current-page="currentPage"
        @update-per-page="(val) => (perPage = val)"
        @update-current-page="(val) => (currentPage = val)"
      />
    </Card>
  </Page>
</template>

<script>
import moment from "moment";
import debounce from "debounce";

export default {
  data() {
    return {
      showColumnsTogle: false,
      searchFocused: false,
      updated_voucher: null,
      sortingPriority: [{ field: "id", order: "asc" }],
      singleSorting: {},
      currentPage: 1,
      perPage: 50,
      perPages: [50, 100, 200],
      vouchers_loading: true,
      total: 0,
      headers: [
        {
          label: this.$t("site"),
          value: "site_id",
          sortable: true,
          searchable: true,
          width: "80",
          filter_type: "select",
          filter_text: "label",
          filter_id: "id",
          filter_values: [],
          input: "",
          filter: "is",
          display: true,
        },
        {
          label: this.$t("id"),
          value: "id",
          sortable: true,
          searchable: true,
          width: "80",
          filter_type: "numeric",
          input: "",
          filter: "is",
          display: true,
        },
        {
          label: this.$tc("customer", 1),
          value: "user",
          sortable: true,
          searchable: true,
          width: "200",
          input: "",
          filter: "contains",
          display: true,
        },
        {
          label: this.$i18n.t("discount"),
          value: "amount",
          sortable: true,
          searchable: true,
          width: "120",
          filter_type: "numeric",
          input: "",
          filter: "is",
          display: true,
        },
        {
          label: this.$tc("code", 1),
          value: "voucher",
          sortable: true,
          searchable: true,
          width: "240",
          input: "",
          filter: "contains",
          display: true,
        },
        {
          label: this.$tc("brand", 1),
          value: "brand_id",
          sortable: true,
          searchable: true,
          width: "120",
          input: "",
          filter: "contains",
          display: false,
        },
        {
          label: this.$tc("category", 1),
          value: "category_id",
          sortable: true,
          searchable: true,
          width: "120",
          input: "",
          filter: "contains",
          display: false,
        },
        {
          label: this.$tc("min_amount", 1),
          value: "min_amount",
          sortable: true,
          searchable: true,
          width: "120",
          filter_type: "numeric",
          input: "",
          filter: "is",
          display: false,
        },
        {
          label: this.$t("created_at"),
          value: "created_at",
          sortable: true,
          searchable: true,
          width: "160",
          filter_type: "date",
          input: null,
          filter: "is",
          display: false,
        },
        {
          label: this.$t("starts_at"),
          value: "starts_at",
          sortable: true,
          searchable: true,
          width: "160",
          filter_type: "date",
          input: null,
          filter: "is",
          display: true,
        },
        {
          label: this.$t("expire_at"),
          value: "ends_at",
          sortable: true,
          searchable: true,
          width: "160",
          filter_type: "date",
          input: null,
          filter: "is",
          display: true,
        },
        {
          label: this.$t("use_once"),
          value: "use_once",
          sortable: true,
          searchable: true,
          width: "100",
          filter_type: "boolean",
          centered: true,
          input: "",
          display: true,
        },
        {
          label: this.$t("highlight"),
          value: "highlight",
          sortable: true,
          searchable: true,
          width: "100",
          filter_type: "boolean",
          centered: true,
          input: "",
          display: true,
        },
        {
          label: this.$tc("active", 1),
          value: "active",
          sortable: true,
          searchable: true,
          width: "100",
          filter_type: "boolean",
          centered: true,
          input: "",
          display: true,
        },
        {
          label: this.$i18n.t("actions"),
          value: "actions",
          sortable: false,
          width: "80",
          centered: true,
          display: true,
        },
      ],
      items: [],
      sites: [],
      brands: [],
      categories: [],
    };
  },
  watch: {
    currentPage() {
      this.getVouchers();
    },
    perPage() {
      this.getVouchers();
    },
  },
  created() {
    this.updateTitle(this.$tc("voucher", 2));
    if (this.$can("read", "orders.vouchers")) {
      this.getVouchers();
    }
  },
  mounted() {
    this.$bus.$on("save-voucher", (voucher) => {
      if (voucher.index !== -1)
        this.items.splice(voucher.index, 1, voucher.voucher);
      else this.items.unshift(voucher.voucher);
      this.updated_voucher = voucher.voucher.id;
      let self = this;
      setTimeout(function () {
        self.updated_voucher = null;
      }, 2000);
    });
  },
  destroyed() {
    this.$bus.$off("save-voucher");
  },
  methods: {
    showMobile() {
      if (this.$device.mobile) {
        this.searchFocused = true;
        this.$refs.search.focus();
      }
    },
    hideMobile() {
      if (this.$device.mobile) {
        this.searchFocused = false;
      }
    },
    closeDrop() {
      this.$refs["dropdown"].toggle();
    },
    showDrop(field) {
      this.$refs[`filter_drop_${field}`][0].toggle();
    },
    changeFilter(field, value) {
      let index = this.headers.findIndex((h) => h.value == field);
      this.headers[index].filter = value;
      if (this.headers[index].input) this.getVouchers();
    },
    sortPressed(field, order, event) {
      if (event["shiftKey"]) {
        let existingPriority = this.sortingPriority.filter(
          (i) => i.field === field
        )[0];
        if (existingPriority) {
          existingPriority.order =
            existingPriority.order === "desc" ? "asc" : "desc";
        } else {
          if (this.singleSorting.field)
            this.sortingPriority.push(this.singleSorting);
          this.sortingPriority.push({ field, order });
        }
        this.singleSorting = {};
      } else {
        this.sortingPriority = []; // [{field, order}]
        this.singleSorting = { field, order };
      }
      this.getVouchers();
    },
    sortingPriorityRemoved(value) {
      this.sortingPriority = this.sortingPriority.filter(
        (priority) => priority.field !== value
      );
      this.getVouchers();
    },
    getVouchers: debounce(function () {
      let api_params = `?page=${this.currentPage}&per_page=${this.perPage}`;

      if (this.sortingPriority.length > 1) {
        let sortArr = [];
        for (let i = 0; i < this.sortingPriority.length; i++) {
          const sort = this.sortingPriority[i];
          sortArr.push(`${sort.field}:${sort.order}`);
        }
        api_params += `&sorting=${sortArr.join(",")}`;
      } else if (this.sortingPriority.length == 1) {
        api_params += `&sorting=${this.sortingPriority[0].field}:${this.sortingPriority[0].order}`;
      } else if (this.singleSorting.field) {
        api_params += `&sorting=${this.singleSorting.field}:${this.singleSorting.order}`;
      }

      let searchArr = [];
      for (let i = 0; i < this.headers.length; i++) {
        const value = this.headers[i];
        if (
          ["user", "voucher", "brand_id", "category_id"].includes(
            value.value
          ) &&
          value.input &&
          value.input.length != 0 &&
          value.input.length < 3
        )
          return;
        else if (value.input || typeof value.input == "boolean") {
          let obj = {
            id: value.value,
            selected:
              value.value == "ends_at" ||
              value.value == "starts_at" ||
              value.value == "created_at"
                ? moment(value.input).format("YYYY-MM-DD")
                : value.input,
          };
          if (value.filter) obj.operator = value.filter;
          searchArr.push(obj);
        }
      }
      if (searchArr.length)
        api_params += `&filters=${btoa(JSON.stringify(searchArr))}`;

      this.vouchers_loading = true;

      this.$axios
        .get("ecommerce/vouchers" + api_params)
        .then((res) => {
          this.items = res.data.list ? res.data.list : [];
          this.sites = res.data.filters.sites || [];
          this.categories = res.data.filters.categories;
          this.brands = res.data.filters.brands;
          this.headers[0].filter_values = res.data.filters.sites || [];
          this.total = res.data.pagination.total;
        })
        .catch((e) => {
          const code = parseInt(e.response && e.response.status);
          if (code) {
            if (code === 404) {
              this.items = [];
              this.total = 0;
            } else {
              this.clientError(e);
            }
          }
        })
        .finally(() => (this.vouchers_loading = false));
    }, 200),
    confirmDeleting(index, voucher_id) {
      this.$buefy.dialog.confirm({
        message: `<b>${this.$t("are_you_sure")}</b>`,
        confirmText: this.$t("confirm"),
        cancelText: this.$t("cancel"),
        type: "is-danger",
        onConfirm: () => this.deleteVoucher(index, voucher_id),
      });
    },
    deleteVoucher(index, voucher_id) {
      this.$axios
        .delete("ecommerce/vouchers/" + voucher_id)
        .then(() => {
          this.items[index]["active"] = false;
          this.total -= 1;

          // Delete voucher, move to latest
          let voucher_copy = JSON.parse(JSON.stringify(this.items[index]));
          voucher_copy.active = false;
          this.items.splice(index, 1, voucher_copy);
          // this.items.push(voucher_copy);s
        })
        .catch((e) => this.$root.clientError(e));
    },
    restoreVoucher(index, voucher_id) {
      this.$axios
        .put("ecommerce/vouchers/" + voucher_id, { active: true })
        .then((res) => {
          this.items.splice(index, 1, res.data);
          this.updated_voucher = voucher_id;

          let self = this;
          setTimeout(function () {
            self.updated_voucher = null;
          }, 2000);
        })
        .catch((e) => this.$root.clientError(e));
    },
    openForm(index, voucher) {
      //let default_site_id =
      //  this.sites && this.sites.length > 0 ? this.sites[0].id : null;

      let voucher_form = { ...voucher };
      if (index === -1) {
        voucher_form = {
          ...voucher,
          min_amount: { value: null },
          amount: { value: null },
          currency: { code: null },
        };
      }
      let options = {
        key: voucher.id + index,
        index: index,
        voucher: voucher_form,
        sites: this.sites,
        user_id: null,
        action: index == -1 ? "create" : "read",
        from: "ecommerce",
        categories: this.categories,
        brands: this.brands,
      };
      this.openDrawer("voucherForm", options);
    },
  },
};
</script>
